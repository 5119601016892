import React, { useState } from "react"
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Link from 'gatsby-link'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";
import Head from "../components/head";
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/dermal-hero-pop.jpg";
import PopupForm from "../components/PopupForm/PopupForm"



const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Textdesc = styled.p`

font-size:16px;
color: #006b7b;

@media screen and (max-width: 991px)
 {
    font-size:12px;
}

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: #006b7b;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #006b7b;
font-size: 18px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;









const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const NavStyled = styled(Nav)`

  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    font-size:12px;
    &:hover,
    &:active,
    &:visited {
      color: #006b7b !important;
    }
  }
`;

const Faq = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
     <Head title="FAQ Dermamina" image="/images/dermamina-home.jpg"  description="Questions about non surgical treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
      <PageWrapper headerDark footerLight>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Frequently Asked </span> <span style="color: #00aec7;">Questions</span>'
    secondText="FAQ"
    videoAlt="FAQ Non Surgical Treatments"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />
    
        <Section py={0} bg="#ffffff">
       
          <Container className="pt-3 pb-3">
    
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
         
              <Row>
              <Separator />
                <Col md="12" className="mb-md-0 pl-0 pr-0 ml-0 mr-0">
                  <NavStyled className="flex-column pl-0 pr-0 ml-0 mr-0">
                    <Nav.Link eventKey="general">Appointments →</Nav.Link>
                    <Nav.Link eventKey="payments">Payment →</Nav.Link>
                    <Nav.Link eventKey="Dermal">Dermal Filler →</Nav.Link>
                    <Nav.Link eventKey="Nose">Non Surgical Nose Job →</Nav.Link>
                    <Nav.Link eventKey="Anti-Wrinkle">Anti-Wrinkle →</Nav.Link>
                    <Nav.Link eventKey="hair">PRP Hair →</Nav.Link>
                    <Nav.Link eventKey="face">PRP Facial →</Nav.Link>
                    <Nav.Link eventKey="boost">Skin Boosters →</Nav.Link>
                    <Nav.Link eventKey="micro">Microneedling →</Nav.Link>
                    <Nav.Link eventKey="acne">Acne Peel →</Nav.Link>
                    <Nav.Link eventKey="laser">Laser Hair Removal →</Nav.Link>
                    <Nav.Link eventKey="fat">Fat Dissolving →</Nav.Link>
                    <Nav.Link eventKey="hydra">Hydrafacial →</Nav.Link>
                  </NavStyled>
                 
                </Col> <SeparatorEnd />

                <Col className="pt-5" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      <Box>
                      <Box mb={4}>
  <Title variant="card" mb={3}>
    Can I proceed with treatment straight after booking a consultation?
  </Title>
  <Textdesc>
    Yes, as long as there is time left after the consultation.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Can I have additional treatments done on the day?
  </Title>
  <Textdesc>
    Yes, but do let us know in advance.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How can I reschedule or cancel my appointment?
  </Title>
  <Textdesc>
    The confirmation email will have an option to do so. Please click on it and follow the instructions. Please note that appointments cannot be rescheduled or cancelled 48 hours prior to the treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Do you have any cancellations?
  </Title>
  <Textdesc>
    Yes, sometimes we have cancellations. Please check our booking regularly to see what is available, and also visit our Instagram page as we frequently advertise last-minute slots on our stories.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    I booked for one treatment but want a different treatment now, is that ok?
  </Title>
  <Textdesc>
    It is ok to change your mind; just let a member of staff know when you arrive for your appointment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Can a friend or family member come with me?
  </Title>
  <Textdesc>
  Yes, 1 plus one is able to attend your appointment with you. However, please note any children under the age of 18 will not be allowed in the clinic 

  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How old do I have to be to have treatments at Dermamina?
  </Title>
  <Textdesc>
    You have to be 18+ for any treatment at our clinic. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    What are your closest underground/train stations?
  </Title>
  <Textdesc>
    Our clinic is approximately an 8-minute walk from Oxford Circus underground station or a 6-minute walk from Great Portland Street underground station.
  </Textdesc>
</Box>



                       

                        
                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="payments">
                      <Box>
                      <Box mb={4}>
  <Title variant="card" mb={3}>
    Do I have to pay in advance?
  </Title>
  <Textdesc>
    A deposit will be required to book any treatment at Dermamina. This will be deducted from the cost of your treatment on the day of your appointment. The payment will be made in full at the end of your appointment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Do you have finance options?
  </Title>
  <Textdesc>
    Unfortunately, not at the moment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How can I pay?
  </Title>
  <Textdesc>
    We accept: cash, card, and bank transfer.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Dermal">
                      <Box>
                      

                      <Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect on the day of the treatment?
  </Title>
  <Textdesc>
    Before any treatment and every visit, an in-depth consultation will take place. This will allow us to understand what you want to achieve.<br /><br />
    Numbing cream will then be applied (if required). Once the numbing cream is in full effect, we will start with the treatment. We prioritize patient involvement throughout the treatment journey, striving to achieve your desired outcome together.<br /><br />
    Our ultimate goal is for you to leave feeling happy and confident, loving your enhancement and feeling more self-assured.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Are Dermal Fillers for me?
  </Title>
  <Textdesc>
    Before any treatment and at every visit, an in-depth consultation will be conducted. During this consultation, all your questions and concerns will be addressed, and together, we will determine if the treatment is suitable for you.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How long do Dermal Fillers last for?
  </Title>
  <Textdesc>
    Depending on what treatment you are getting, it lasts roughly as stated below:<br /><br />
    Lip Enhancement – 6 months<br />
    Cheek Augmentation – 12-18 months<br />
    Nasolabial Folds – 12-18 months<br />
    Corner of the mouth – 12-18 months<br />
    Temple filler - 9 - 18 months<br />
    Tear Trough – 12 months<br />
    Chin/Jaw Augmentation - 12-18 months<br />
    Non-Surgical Rhinoplasty or Non-Surgical Nose job – 12-18 months<br />
    *Please note, this will vary per person, as it is based on many factors such as skin type, skin condition, ages, lifestyle, and natural metabolism.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    When will I see results?
  </Title>
  <Textdesc>
    Results are instant, however, due to swelling you incur you will see the final result up to 2 weeks after the initial treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How long does the treatment take?
  </Title>
  <Textdesc>
    Usually takes up to 15-20 minutes, however, this also depends on the client and how often we will need to stop throughout the treatment. Provided you book in for several treatments - it may take longer.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Do treatments hurt?
  </Title>
  <Textdesc>
    The pain depends on which treatment you are getting and also depends on your own pain threshold. Some may feel no pain at all, whereas others will feel slight pain. We do use a topical numbing cream to help with the pain and to give you a more comfortable experience.<br /><br />
    Below is based on feedback from clients:<br />
    Tear Trough the pain is very minimal<br />
    Lip enhancement/Cheek Augmentation/Nasolabial Folds/Corner of the mouth/Rhinoplasty the pain is light and there is a slight discomfort**<br />
    **Clients describe the pain as tiny scratches with a slight stinging sensation.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Will I experience swelling or bruising?
  </Title>
  <Textdesc>
    With Dermal filler treatments you may experience swelling and/or bruising after the treatment (which is very normal). On average, swelling fades within 48 hours, and bruising could take up to a fortnight. Please allow two weeks for the area to fully heal and see the final result.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    What are the side effects?
  </Title>
  <Textdesc>
    The side effects of dermal filler may include mild bruising and swelling, which is very normal. There may also be a slight redness in the injected areas. While most side effects are minor and temporary, there is a small chance of long-term side effects with some treatments. Your practitioner will explain all potential risks and side effects during the consultation before the treatment to ensure you are well-informed and prepared.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How soon can I return to work?
  </Title>
  <Textdesc>
    With all treatments, you should be able to return to work the same day or the following day.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Will I be left with any scars?
  </Title>
  <Textdesc>
    No permanent marks will be left after any treatment. Maybe just a little bruising that usually goes after a few days.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Are Dermal Fillers safe?
  </Title>
  <Textdesc>
    Absolutely! As fillers are a hyaluronic acid, which is a sugar molecule that occurs naturally in the human body. Your safety is our number one priority. All our treatments are safe; some treatments may have side effects, but this will all be explained to you during the consultation before the treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Are treatments just for females?
  </Title>
  <Textdesc>
    Absolutely not! Male or Female, we all want to look our best and stay looking young.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Can you have Dermal Fillers when you are pregnant or breastfeeding?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How old do you have to be to have the treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Can I bring a friend along?
  </Title>
  <Textdesc>
  Yes, 1 plus one is able to attend your appointment with you. However, please note any children under the age of 18 will not be allowed in the clinic 
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Nose">
                      <Box>
                      <Box mb={4}>
                          <Title variant="card" mb={3} >
Why patients trust Dermamina?           </Title>
                          <Textdesc>
                          We’re renowned for providing natural-looking results. Do not just take our word for it, see our before & after images to see some of our work.<br />
If we believe the procedure is not a suitable solution for you, we will not recommend the treatment.
</Textdesc>
                        </Box>


                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Does the treatment hurt?          </Title>
                          <Textdesc>
                          We use numbing cream to make your experience as smooth as possible. <br />
Based on all our previous patients, the pain is mild & some patients felt no pain at all.
</Textdesc>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} >
How long is recovery?          </Title>
                          <Textdesc>
                          No recovery time needed. You can get straight back to your daily routine, without taking time off work.

</Textdesc>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Is the treatment safe?          </Title>
                          <Textdesc>
                          Yes it is safe. The filler used is a hyaluronic acid, which is a sugar molecule that occurs naturally in the human body. Risk is involved due to the area being complex but everything will fully be explained in the consultation before the treatment. Your safety is our number one priority.

</Textdesc>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Are there any side effects?          </Title>
                          <Textdesc>
                          No major side effects like a surgical nose job. <br />
The nose after treatment may feel slightly tender and sore for a few days, but nothing visible i.e. bruising.

</Textdesc>
                        </Box>


                        <Box mb={4}>
                          <Title variant="card" mb={3} >
What if I am unhappy with the result?          </Title>
                          <Textdesc>
                          All treatments are reversible if required.


</Textdesc>
                        </Box>


                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Will this treatment make my nose smaller or bigger?          </Title>
                          <Textdesc>
                          The treatment can not make your nose physically smaller, however it will give the illusion that the nose is smaller.<br />
The treatment is designed to correct the shape of the nose as well as making the nose look narrower (see before/after pictures for examples)

</Textdesc>
                        </Box>


                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Will there be any marks or bruising?          </Title>
                          <Textdesc>
                          No, Non-Surgical Rhinoplasty treatments do not usually leave visible marks but some may bruise. Arnica tables or gel can be taken/used before or after the treatment to reduce this.



</Textdesc>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} >
Can i do the treatment after consultation?         </Title>
                          <Textdesc>
                          Yes, In most cases treatment can take place straight after your consultation.


</Textdesc>
                        </Box>

                        <Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Anti-Wrinkle">
                      <Box>
                        
                      <Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect on the day of the treatment?
  </Title>
  <Textdesc>
    Before any treatment and every visit, an in-depth consultation will take place. This will allow us to understand what you want to achieve. Numbing cream will then be applied (if required). Once the numbing cream is in full effect, we will start with treatment. We like to keep our patients involved during the treatment so that together we can achieve exactly what you want. At the end of the day, we want you to leave very happy, loving your enhancement, and feeling that little bit more confident.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    How long does the result last?
  </Title>
  <Textdesc>
    Results can last up to 6 months; however, this may vary from person to person as it is based on many factors such as skin type, skin condition, ages, lifestyle, and natural metabolism.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    When will I see results from anti-wrinkle injections?
  </Title>
  <Textdesc>
    You may notice initial improvements within 7-10 days of treatment. However, the full effects usually become more noticeable between the second and fourth week after treatment. By this time, your skin will appear smoother, and wrinkles will be significantly reduced.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    What areas are included?
  </Title>
  <Textdesc>
    Frown lines<br />
    Gummy Smile<br />
    Forehead lines<br />
    Crows feet<br />
    Brow lift<br />
    Downturned corner of the mouth<br />
    Dimpled chin<br />
    Masseter
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3} >
    Do these treatments hurt?
  </Title>
  <Textdesc>
    The level of pain experienced during treatments is generally minimal, but it can vary depending on the specific treatment area and individual pain tolerance. Some people may feel no pain at all, while others may experience a slight pinch. To help alleviate any discomfort, we can use numbing cream to ensure a more comfortable experience.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Will I experience swelling or bruising?
  </Title>
  <Textdesc>
    Usually no bruising or swelling, however small bumps may appear at the injection points. These should subside in a few hours.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are the treatments safe?
  </Title>
  <Textdesc>
    Yes, all treatments we offer are safe. Prior to any treatment, we conduct a comprehensive consultation to discuss and address any concerns you may have. While our treatments are generally safe, some may have potential side effects; however, our clinic staff are highly trained and experienced, ensuring that patients are in safe and capable hands throughout their treatment journey.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    At what age should I start this treatment?
  </Title>
  <Textdesc>
    There is no exact age as it all depends on the person and their lifestyle. When you start to notice wrinkles then know it is time. The sooner you do it the easier the treatment, as if you leave it too late then the use of filler may be needed.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    I have excessive sweating, can this treatment help?
  </Title>
  <Textdesc>
    Yes, we have had great results.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Do you have a treatment for my Gummy Smile?
  </Title>
  <Textdesc>
    Yes, we can use anti-wrinkle injections to reduce the appearance of a gummy smile by relaxing the muscles that pull the upper lip up.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is this treatment for me?
  </Title>
  <Textdesc>
    Before any treatment and at every visit, an in-depth consultation will be conducted. During this consultation, all your questions and concerns will be addressed and together, we will determine if the treatment is suitable for you.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What are the side effects?
  </Title>
  <Textdesc>
    The side effects are that there may be a little bit of redness in the injected areas. Nothing major or harmful in the long run.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Will I be left with any scars?
  </Title>
  <Textdesc>
    No permanent marks will be left after any treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How soon can I return to work?
  </Title>
  <Textdesc>
    You can return to work right after the treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are treatments just for females?
  </Title>
  <Textdesc>
    Absolutely not! Male or Female, we all want to look our best and stay looking young.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can I bring a friend along?
  </Title>
  <Textdesc>
    Yes.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>
                      
</Box>
</Tab.Pane>



 <Tab.Pane eventKey="hair">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect on the day of my treatment?
  </Title>
  <Textdesc>
    PRP hair restoration is a treatment that takes up to 40 minutes. A sample of your blood is retrieved and placed in a centrifuge to separate the platelet-rich plasma. The enriched platelet plasma is extracted from the blood and injected into your scalp to help promote hair growth and boost collagen.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Am I suitable for treatment?
  </Title>
  <Textdesc>
    Most people are suitable for PRP; unfortunately, there are certain cases where a patient may not be suitable, and the reason can vary. In some instances, lack of hair follicles can make a patient not suitable. Any type of blood cancers and blood disorders would also make a patient not suitable. Optimal health produces better results.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How should I prepare for treatment?
  </Title>
  <Textdesc>
    For up to 48 hours to a week before your treatment, avoid blood-thinning medications, alcohol, and anti-inflammatories.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How many treatments do I need?
  </Title>
  <Textdesc>
    The number of treatments required varies from patient to patient. PRP is a customizable treatment to target each patient's individual needs.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What is the downtime?
  </Title>
  <Textdesc>
    There is no need for recovery time after a PRP session as it is the least invasive hair restoration treatment; you can return to your daily routine right away. PRP treatment takes less than 10 minutes, and appointments are only 30 minutes long. PRP is injected into the scalp with very small needles, causing minor discomfort.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is it a permanent solution?
  </Title>
  <Textdesc>
    PRP is not a permanent solution for hair loss as it only restores the follicle's ability to regrow hair. Additional injections may be needed to maintain and upkeep results.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are there any side effects?
  </Title>
  <Textdesc>
    After treatment, there may be slight pain or tenderness with some discomfort on the injection site, which is normal. Patients can also experience slight swelling or bruising which should resolve on its own. Other more serious side effects, which are extremely rare, would be infection. We at Dermamina combat this by using red light therapy to ensure bacteria are killed, lowering the chances of infection. Another extremely rare side effect is an allergic reaction.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>       
</Box>
</Tab.Pane>


<Tab.Pane eventKey="face">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What is PRP facial?
  </Title>
  <Textdesc>
    PRP Facial, also known as Platelet Rich Plasma (PRP) facial rejuvenation treatment. It is a highly effective natural and minimally invasive facial treatment that harnesses your body’s natural healing properties to repair and rejuvenate your skin, and reduce signs of ageing.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect on the day of my treatment?
  </Title>
  <Textdesc>
    PRP facial is a treatment that takes up to 40 minutes. A sample of your blood is retrieved and placed in a centrifuge to separate the platelet-rich plasma. The enriched platelet plasma is extracted from the blood and applied to the skin, providing your skin a fresher, tighter, healthier, and more youthful glow in one of the most natural ways.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How should I prepare for treatment?
  </Title>
  <Textdesc>
    For up to 48 hours to a week before your treatment, avoid blood-thinning medications, alcohol, and anti-inflammatories.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is PRP facial safe?
  </Title>
  <Textdesc>
    Yes, PRP treatments are safe. It is a 100% natural procedure using your own blood, so there is no risk of an allergic reaction. PRP treatments, for many years have been and are currently in use for medical purposes, such as healing of injured muscles and joints, tendons, and ligaments.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Does PRP Facial hurt?
  </Title>
  <Textdesc>
    The treatment is minimally invasive and generally painless. The needles used are among the thinnest, making the procedure virtually painless. Local anaesthetic cream is also available if required.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How many treatments do I need?
  </Title>
  <Textdesc>
    3-4 sessions spaced 3-4 weeks apart, are recommended for best results. Following the first 4 sessions, a top-up treatment 6 months later is advisable and then on an annual basis to help maintain skin rejuvenation. During your initial consultation, we will go through this together.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What are the side effects of PRP on the face?
  </Title>
  <Textdesc>
    Slight swelling and redness are the most common side effects. They typically appear immediately after the procedure and clear within four to six days. You'll also want to avoid sun exposure and harsh skin treatments during this time. It's important that you don't rub or pick at your face.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How soon will I see results from PRP Facial?
  </Title>
  <Textdesc>
    For PRP treatments of the face, you can expect to see some results starting at 2 or 3 weeks. These results will be a brighter complexion, a healthy glow to the skin, and even a more plump skin texture.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can PRP reduce eye bags?
  </Title>
  <Textdesc>
    PRP Face is an effective treatment for the under-eye area as it increases blood vessel growth and strengthens collagen promoting a refreshed and youthful appearance. New blood vessels in the under-eye area allow nutrients and oxygen into the skin, resulting in a decrease of puffiness and loose under-eye skin is tightened.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>       
</Box>
</Tab.Pane>


<Tab.Pane eventKey="boost">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    Which skin boosters do you offer?
  </Title>
  <Textdesc>
    We provide Profhilo, Seventy Hyal 2000, RHA1, Redensity, and Sunekos skin booster treatments.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What to expect from the procedure?
  </Title>
  <Textdesc>
    Consultation: You will meet with one of our practitioners prior to treatment to discuss your goals, assess your skin condition, and determine if you are a suitable candidate for the procedure.<br /><br />
    Preparation: Before the procedure, the treatment area will be cleansed, and numbing cream will be applied to minimize discomfort during the injections.<br /><br />
    Injection: This is then injected into specific areas of the face using a fine needle. The injector will strategically place the skin booster to help treat the physiological depletion of hyaluronic acid (HA) within the skin. The supplementation of hyaluronic acid (HA) delivers moisture-surging hydration whilst simultaneously stimulating the production of new collagen and elastin.<br /><br />
    After the injections, small, localized bumps may appear at the injection points as expected with any injectable procedure. These small bumps can last anywhere from 1 hour to 48 hours before dispersing under the skin.<br /><br />
    What to expect: Bruising is the most common side effect and usually shows immediately and will last for 3-5 days. There can also be some mild swelling and tenderness. This is all completely normal and generally reduces within a few days to a week. No recovery time is required, and you can return to your daily activities immediately.<br /><br />
    The longevity of results can vary between individuals; however, Results are expected to show almost immediately with full results showing two weeks post-treatment. 2-3 sessions of the treatment depending on initial skin quality spaced four weeks apart may be recommended to maintain the desired results. Treatments will then need to be topped up every six to 12 months.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What areas can be treated with Skin Boosters?
  </Title>
  <Textdesc>
    Our skin boosters prove highly effective for enhancing the face, neck, decolletage, and hands. The face is the most commonly treated location.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is there downtime/recovery time after Skin Booster treatment?
  </Title>
  <Textdesc>
    The downtime or recovery time after skin booster injections is generally minimal. Many individuals can resume their normal activities almost immediately after the treatment. However, it's essential to note that individual responses to the injections can vary. Some may experience mild swelling, redness, or bruising at the injection sites, but these effects are usually temporary and resolve within a short period.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are Skin Booster treatments painful?
  </Title>
  <Textdesc>
    Patients typically report minimal and easily manageable discomfort. We recognize that pain tolerance varies among individuals, and we are pleased to offer anesthetic cream to patients before commencing the treatment. It's normal to experience slight discomfort after the procedure, but significant pain is not anticipated. We will provide post-treatment guidance, and a comprehensive summary will be sent directly to your email. This ensures that patients are well-informed about what to expect and the recommended aftercare measures to follow.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>               
</Box>
</Tab.Pane>


<Tab.Pane eventKey="micro">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What exactly is Microneedling?
  </Title>
  <Textdesc>
    Microneedling is a minimally invasive aesthetic procedure in which tiny, sterile needles are used to form micro-channels in the skin. This procedure enhances the body's natural healing response, resulting in increased collagen and elastin synthesis, enhanced skin texture, and a reduction in the visibility of scars, wrinkles, and other skin flaws.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Does Microneedling cause pain?
  </Title>
  <Textdesc>
    Although Microneedling is generally safe, some individuals may experience slight discomfort during the process. To alleviate any discomfort, a numbing cream can be given to the treatment region.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect from Microneedling?
  </Title>
  <Textdesc>
    Micro-needling can help with fine lines and wrinkles, acne scars, hyperpigmentation, and uneven skin texture. It can also tighten the skin, giving it a more youthful and bright appearance.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is Microneedling appropriate for all skin types?
  </Title>
  <Textdesc>
    Yes, Microneedling is suitable for all skin types and colors. Your skincare specialist, on the other hand, will assess your skin and medical history to determine if micro-needling is the best treatment for you.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    Although it is unlikely to have an effect on you or your baby, we advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>           
</Box>
</Tab.Pane>


<Tab.Pane eventKey="acne">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What is Acne Peel?
  </Title>
  <Textdesc>
    At Dermamina, we use EnerPeel® Salicylic Acid Skinpeels for our acne treatment, which targets all visible symptoms and the unseen primary causes of acne, by diminishing oil production, destroying acne-causing bacteria, and reducing inflammation.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Why choose Skinpeel for Acne?
  </Title>
  <Textdesc>
    - Skinpeel is a medically licensed acne treatment<br />
    - Addresses all underlying causes of acne<br />
    - Treats all visible and invisible symptoms<br />
    - Clinical studies carried out by eminent dermatologists<br />
    - Long-term acne solution
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is a consultation provided on the day of the treatment?
  </Title>
  <Textdesc>
    Yes. At Dermamina, pre-treatment consultations are crucial in ensuring that patients are well-prepared and at ease in the hands of their therapists, as well as, most significantly to ensure the patient is fully informed about the treatment and also understands what the patient is looking to achieve.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What should I expect on the day of my treatment?
  </Title>
  <Textdesc>
    The appointment consists of a consultation whereby your therapist will closely assess the condition of your skin and examine your acne to understand your concern and prescribe the best course of treatment and a skincare program. If deemed suitable and you are happy to proceed, the treatment will then commence straight after.
    <ol>
      <li>Face will be cleansed</li>
      <li>Peel will be applied for a few minutes (avg. 5 mins)</li>
      <li>Peel will be removed</li>
    </ol>
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How many treatments are needed?
  </Title>
  <Textdesc>
    Depending on the severity of acne, a course of 3-6 peels will need to be administered. Each session will need 14 days space between each.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How should I prepare for Acne Peel treatment?
  </Title>
  <Textdesc>
    - Avoid excessive sun exposure/burns for 24hrs prior to procedure<br />
    - Discontinue use of topical retinoids, hydrocortisone 2 weeks prior to procedure<br />
    - Avoid treatment if you have an active breakout of skin acne, open lesions, cold sore, and skin infection
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    Although it is unlikely to have an effect on you or your baby, we advise waiting after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>             
</Box>
</Tab.Pane>



<Tab.Pane eventKey="laser">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What is Laser Hair Removal?
  </Title>
  <Textdesc>
    Laser Hair Removal is a cosmetic procedure that uses concentrated light to remove unwanted hair.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How does it work?
  </Title>
  <Textdesc>
    The laser emits a light that is absorbed by the pigment in the hair follicles, damaging them and inhibiting future hair growth.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can it be done on any skin type?
  </Title>
  <Textdesc>
    Advanced lasers allow treatment on a range of skin tones, but it's essential to use the right technology for specific skin types. The laser we use here at Dermamina is the Venus Velocity.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is it painful?
  </Title>
  <Textdesc>
    Sensations vary, but many people describe it as a tolerable tingle, with some feeling mild discomfort, but the treatment is pain-free.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How many sessions are needed?
  </Title>
  <Textdesc>
    Multiple sessions are usually required for optimal results, as hair grows in different cycles.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How long between sessions?
  </Title>
  <Textdesc>
    Typically, sessions are spaced 4-6 weeks apart to target hair in various growth stages.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are the results permanent?
  </Title>
  <Textdesc>
    Laser hair removal can lead to long-term hair reduction, but results vary, and some maintenance sessions may be needed.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are there side effects?
  </Title>
  <Textdesc>
    Temporary side effects may include redness, swelling, or minor discomfort.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is there downtime?
  </Title>
  <Textdesc>
    Most people can resume normal activities immediately, though sun exposure and sun beds should be limited 2 weeks before and after treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can it be done on any body part?
  </Title>
  <Textdesc>
    Laser hair removal is effective on various body parts, including the face, legs, underarms, and bikini line.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>      
</Box>
</Tab.Pane>




<Tab.Pane eventKey="fat">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    How does it work?
  </Title>
  <Textdesc>
    We use the leading Aqualyx for fat dissolving. Aqualyx is a type of fat dissolving treatment that targets localised areas of stubborn fat. A natural substance called deoxycholic, which naturally occurs in the body is injected directly into the area of excess body fat helping to break down and eliminate fat. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What areas can be treated?
  </Title>
  <Textdesc>
    Areas that could be treated (subject to suitability) include: <br />
    - Thighs <br />
    - Bingo wings <br />
    - Double chin <br />
    - Back, including bra back fat <br />
    - Stomach and six-pack definition/ six-pack abs treatment <br />
    - Waistline/ hips <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is a consultation provided on the day of the treatment?
  </Title>
  <Textdesc>
    Yes. At Dermamina, pre-treatment consultations are crucial in ensuring that patients are well-prepared and at ease in the hands of their therapists, as well as, most significantly to ensure the patient is fully informed about the treatment and also understands what the patient is looking to achieve. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    What to expect during fat loss treatment?
  </Title>
  <Textdesc>
    A fine needle is used during the procedure to inject the Aqualyx right into the troublesome fat. During the injection, some patients may feel a minor stinging or burning sensation, but this is often brief and mild. Some individuals may suffer minor swelling, redness, or bruising in the treated area after the injection, although these symptoms are expected and usually go away within a few days. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How many fat dissolving sessions will I need?
  </Title>
  <Textdesc>
    Sessions needed depend on the individual and the area treated. For example, under the chin usually may require just one treatment, whereas the stomach area could require 2-3. We cannot determine without going through a thorough consultation and assessment as each person's fat is unique and may need more or less than others. Most people see a difference right away after just one session. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is fat dissolving painful?
  </Title>
  <Textdesc>
    Fat dissolving is regarded as a non-invasive procedure. Pain experienced during Aqualyx can vary from person to person. The majority only experience a little discomfort throughout the treatment as a fine needle is used. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is Aqualyx fat dissolving safe?
  </Title>
  <Textdesc>
    Aqualyx is often regarded as safe in the hands of a trained and knowledgeable provider. Deoxycholic acid, an organic compound present in the body that aids in the breakdown and elimination of fat, is the active component of Aqualyx. This fat dissolving substance has been extensively studied and has been approved for use since 2009. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can Men have fat dissolving?
  </Title>
  <Textdesc>
    Most certainly. It is a common treatment for men, especially in helping with reducing stomach fat and could help attain a six-pack figure/six-pack abs. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How should I prepare for fat dissolving treatment?
  </Title>
  <Textdesc>
    - Area is advised to be clean and makeup-free <br />
    - Shaving is recommended but not a necessity <br />
    - Forms all completed as all pre-treatment care must be followed <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can I go on a flight after fat dissolving?
  </Title>
  <Textdesc>
    We advise leaving 48 hours post-treatment before going on a flight. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    We advise waiting after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding. <br />
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments. <br />
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>      
</Box>
</Tab.Pane>




<Tab.Pane eventKey="hydra">
 <Box>
 <Box mb={4}>
  <Title variant="card" mb={3}>
    What is a HydraFacial?
  </Title>
  <Textdesc>
    HydraFacial is a non-invasive facial treatment that uses patented technology to cleanse, exfoliate, extract impurities, and hydrate the skin.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How does it work?
  </Title>
  <Textdesc>
    The treatment involves a multi-step process: cleansing and exfoliation, acid peel, extractions, hydration, and antioxidant protection.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Is it painful?
  </Title>
  <Textdesc>
    Most people find HydraFacial comfortable and not painful. It's generally well-tolerated and doesn't require downtime.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How long does it take?
  </Title>
  <Textdesc>
    A HydraFacial session takes around 40 minutes, making it a quick and efficient treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are there any side effects?
  </Title>
  <Textdesc>
    Side effects are usually minimal. Some people may experience mild redness, but it typically subsides shortly after the treatment.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How often should I get a HydraFacial?
  </Title>
  <Textdesc>
    The frequency depends on your skin type and concerns, but many individuals opt for a monthly treatment to maintain optimal results.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can it be customized for specific skin concerns?
  </Title>
  <Textdesc>
    Yes, HydraFacial can be tailored to address various skin issues, including fine lines, wrinkles, hyperpigmentation, and acne. We at Dermamina have 3 unique tiers of Hydrafacials available to accommodate patients' varying needs.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Who is a good candidate?
  </Title>
  <Textdesc>
    HydraFacial is suitable for most skin types, but it's best to consult with a skincare professional to determine if it's right for you.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can I wear makeup afterward?
  </Title>
  <Textdesc>
    You can typically apply makeup immediately after the treatment since there's no downtime associated with HydraFacial.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Are the results long-lasting?
  </Title>
  <Textdesc>
    While you may notice an immediate improvement in your skin's appearance, maintenance treatments are often recommended for long-lasting results.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    Can you have this treatment when pregnant?
  </Title>
  <Textdesc>
    Although it is unlikely to have an effect on you or your baby, we advise waiting until after you deliver and finish breastfeeding. Therefore, we do not treat patients that are pregnant or breastfeeding.
  </Textdesc>
</Box>

<Box mb={4}>
  <Title variant="card" mb={3}>
    How old do I have to be to have this treatment?
  </Title>
  <Textdesc>
    You have to be 18+ for treatment. Please make sure you have some form of ID with you for the treatments.
  </Textdesc>
</Box>
<Box mb={4}>
                          Didn’t find your answer?{" "}
                         <Link to ="/contact">
                            <Span color="#006b7b">Contact us here</Span>
                            </Link>
                        </Box>            
</Box>
</Tab.Pane>





                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    
    </>
  );
};
export default Faq;
